import {DatePipe, KeyValuePipe, NgClass, UpperCasePipe} from '@angular/common'
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  viewChild,
  viewChildren
} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatCard} from '@angular/material/card'
import {MatDialog} from '@angular/material/dialog'
import {MatDivider} from '@angular/material/divider'
import {MatIcon} from '@angular/material/icon'
import {Router} from '@angular/router'
import {DetailedFamily, MenuDayConfig} from '@ellen/user-be'
import {filter, first, Subscription} from 'rxjs'
import {
  CREATE_MENU_ROUTE,
  CREATE_MENU_SUMMARY_ROUTE,
  INTERNAL_MODULE
} from '../../../../application/constants'
import {DailyMealPipe} from '../../../../pipes/menu-meal.pipe'
import {MenuQualityPipe} from '../../../../pipes/menu-quality.pipe'
import {SplitPipe} from '../../../../pipes/split.pipe'
import {RecipesService} from '../../../../services/recipes.service'
import {UserService} from '../../../../services/user.service'
import {CarouselUtils} from '../../../../utils/carousel.utils'
import {AppAddsComponent} from '../../../common/app-adds/app-adds.component'
import {AvatarComponent} from '../../../common/avatar/avatar.component'
import {
  GptLoaderComponent
} from '../../../common/gpt-loader/gpt-loader.component'
import {
  CreateMenuWarningDialogComponent
} from '../create-menu-warning-dialog/create-menu-warning-dialog.component'
import {
  CreateMenuPlanDayConfigComponent,
  MenuDayConfigFE
} from './day-config/create-menu-plan-day-config.component'

@Component({
  selector: 'eln-create-menu-plan',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatDivider,
    AvatarComponent,
    MenuQualityPipe,
    KeyValuePipe,
    DailyMealPipe,
    MatIcon,
    SplitPipe,
    NgClass,
    GptLoaderComponent,
    CreateMenuPlanDayConfigComponent,
    DatePipe,
    UpperCasePipe,
    AppAddsComponent
  ],
  templateUrl: './create-menu-plan.component.html',
  styleUrls: ['./create-menu-plan.component.scss', '../create-menu.scss']
})
export class CreateMenuPlanComponent implements OnInit, OnDestroy, AfterViewInit {

  public userService: UserService = inject(UserService)
  public recipesService: RecipesService = inject(RecipesService)

  public daysConfig: MenuDayConfigFE[] = []

  public daysConfigHolder =
    viewChild.required<ElementRef<HTMLDivElement>>('daysConfigHolder')
  public dayNumbers =
    viewChildren<ElementRef<HTMLDivElement>>('dayNumber')
  public dayConfigs =
    viewChildren<ElementRef<HTMLDivElement>>('dayConfig')

  protected carouselUtils!: CarouselUtils
  private router: Router = inject(Router)
  private dialog: MatDialog = inject(MatDialog)

  private sub$ = new Subscription()

  public ngOnInit() {
    this.userService.family$
      .pipe(
        filter(Boolean),
        first()
      )
      .subscribe(family => {
        this.daysConfig.push(...this.addDefaultDays(family))
      })

    this.carouselUtils = new CarouselUtils(
      this.daysConfigHolder,
      this.dayConfigs,
      this.dayNumbers,
      7,
      this.daysConfig.filter(d => !d.active).length
    )
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
    this.carouselUtils.destroy()
  }

  public ngAfterViewInit() {
    this.carouselUtils.update()
  }

  public isAnyMealActive(): boolean {
    return this.daysConfig.some(d => !d.allInactive)
  }

  public onGenerateMenuClick() {
    // First we check that all family members are valid. If not, we open a
    // dialog
    if (this.userService.familyMembers$().some(u => !u.isValid)) {
      CreateMenuWarningDialogComponent.open(this.dialog)
      return
    }

    // Make sure to not send inactive days or meals. We filter them out here.
    const days: MenuDayConfig[] = this.daysConfig
      .filter(d => d.active && !d.allInactive)
      .map(d => d.getModelBE())

    // Navigate to create menu summary screen after receiving menus.
    this.recipesService.getMenu(this.userService.familyMembers$(), days)
      .subscribe(() =>
        this.router
          .navigate(['/', INTERNAL_MODULE, CREATE_MENU_ROUTE, CREATE_MENU_SUMMARY_ROUTE])
          .then()
      )
  }

  private addDefaultDays(family: DetailedFamily): MenuDayConfigFE[] {
    // IMPORTANT!! Make sure we work with days only => 0h 0min 0sec 0millis

    // It will create two weeks, current week and next one of days to set up.
    // Those days of current week previous than today will be inactive
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    // We do +1 when calculating first day of the week (Monday), because
    // using "getDate()" considers that day 0 (first day) is Sunday.
    const firstDayOfWeek = new Date()
    firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1)
    firstDayOfWeek.setHours(0, 0, 0, 0)

    const daysToSetUp: MenuDayConfigFE[] = []
    for (let i = 0; i < 14; i++) {
      // Create date for day
      const dayDate = new Date(firstDayOfWeek)
      dayDate.setDate(firstDayOfWeek.getDate() + i)
      // Create config for day
      const dayToSetUp = new MenuDayConfigFE(
        dayDate.getTime(),
        {
          familyMembers: family.familyMembers.map(u => u.id),
          guests: 0
        },
        {
          familyMembers: family.familyMembers.map(u => u.id),
          guests: 0
        }
      )
      dayToSetUp.active = dayDate >= today
      daysToSetUp.push(dayToSetUp)
    }
    return daysToSetUp
  }
}
