import {DatePipe, KeyValuePipe, NgClass, UpperCasePipe} from '@angular/common'
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
  viewChildren,
  WritableSignal
} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatCard} from '@angular/material/card'
import {MatDialog} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {Router} from '@angular/router'
import {
  GeneratedRecipe,
  IFamilyMenuDay,
  sortByDay,
  sortByMeal
} from '@ellen/user-be'
import {filter, Subscription, tap} from 'rxjs'
import {environment} from '../../../../../environments/environment'
import {INTERNAL_MODULE} from '../../../../application/constants'
import {DailyMealPipe} from '../../../../pipes/menu-meal.pipe'
import {RecipesService} from '../../../../services/recipes.service'
import {UserService} from '../../../../services/user.service'
import {CarouselUtils} from '../../../../utils/carousel.utils'
import {AppAddsComponent} from '../../../common/app-adds/app-adds.component'
import {
  ConfirmationDialogComponent
} from '../../../common/confirmation-dialog/confirmation-dialog.component'
import {
  GptLoaderComponent
} from '../../../common/gpt-loader/gpt-loader.component'
import {
  RecipeContentComponent
} from '../../recipe/recipe-content/recipe-content.component'
import {
  RecipePromptingComponent
} from '../../recipe/recipe-prompting/recipe-prompting.component'

@Component({
  selector: 'eln-create-menu-summary',
  standalone: true,
  imports: [
    MatButton,
    DailyMealPipe,
    NgClass,
    KeyValuePipe,
    MatIcon,
    RecipeContentComponent,
    MatCard,
    GptLoaderComponent,
    AppAddsComponent,
    DatePipe,
    UpperCasePipe,
    RecipePromptingComponent
  ],
  templateUrl: './create-menu-summary.component.html',
  styleUrls: ['./create-menu-summary.component.scss', '../create-menu.scss']
})
export class CreateMenuSummaryComponent implements OnInit, AfterViewInit, OnDestroy {

  public recipesHolder =
    viewChild.required<ElementRef<HTMLDivElement>>('recipesHolder')
  public dayNumbers =
    viewChildren<ElementRef<HTMLDivElement>>('dayNumber')
  public recipeContents =
    viewChildren<ElementRef<HTMLDivElement>>('recipeContent')

  public generatedRecipes: GeneratedRecipe[] = []
  public activeGeneratedRecipe: WritableSignal<GeneratedRecipe | null> = signal(null)

  protected readonly environment = environment
  protected carouselUtils!: CarouselUtils
  protected recipesService: RecipesService = inject(RecipesService)
  private userService: UserService = inject(UserService)
  private router: Router = inject(Router)
  private dialog: MatDialog = inject(MatDialog)
  private currentSchedule: IFamilyMenuDay[] = []

  private subs$: Subscription[] = []

  public ngOnInit() {
    this.subs$.push(this.userService.family$
      .pipe(
        filter(Boolean),
        filter(family => !!family.schedule)
      )
      .subscribe(family =>
        this.currentSchedule = family.schedule!)
    )

    this.subs$.push(this.recipesService.generatedRecipes$
      .pipe(
        // If there are no generated menus, we'll send user back home
        tap((val) => {
          if (!val) {
            this.router.navigate([INTERNAL_MODULE]).then()
          }
        }),
        filter(Boolean)
      )
      .subscribe(recipes => {
        // Save generated recipes locally
        this.generatedRecipes = recipes
        // Sort them by day and meal. 1st by day, then by meal
        // Sort all recipes by day and meal
        this.generatedRecipes.sort((a, b) => {
          const p = sortByDay(a.day, b.day)
          if (p === 0) {
            return sortByMeal(a.meal, b.meal)
          }
          return p
        })

        // If there is no recipe selected yet, we select the first one
        if (!this.activeGeneratedRecipe()) {
          this.activeGeneratedRecipe.set(this.generatedRecipes[0])
        }
      }))

    this.carouselUtils = new CarouselUtils(
      this.recipesHolder, this.recipeContents, this.dayNumbers, 4)
  }

  public ngAfterViewInit() {
    this.carouselUtils.update()

    // Listen to any change of active item in Carousel, and get corresponding
    // GeneratedRecipe
    this.subs$.push(this.carouselUtils.activeChange$
      .subscribe((index) => {
        // An active item is an existent GeneratedRecipe. There are no errors.
        this.activeGeneratedRecipe.set(this.generatedRecipes[index])
      }))
  }

  public ngOnDestroy() {
    this.subs$.forEach(s => s.unsubscribe())
    this.carouselUtils?.destroy()
  }

  public onRetryClick() {
    this.recipesService.retryGeneratedRecipe(
      this.userService.familyMembers$(),
      this.activeGeneratedRecipe()!
    ).subscribe((retriedRecipe) => {
      // Set new active recipe
      this.activeGeneratedRecipe.set(retriedRecipe)
    })
  }

  public onSaveClick() {
    // Check if there is some recipe already in family's schedule for the
    // selected recipe's day.
    const isOverriding = this.currentSchedule.some(d =>
      d.day === this.activeGeneratedRecipe()!.day &&
      ((this.activeGeneratedRecipe()!.meal === 'lunch' && !!d.lunch) ||
        (this.activeGeneratedRecipe()!.meal === 'dinner' && !!d.dinner)))

    if (isOverriding) {
      // If it's overriding, a warning will popup
      ConfirmationDialogComponent.open(this.dialog, {
        title: 'Det finns redan recept för det här tillfället. Vill du skriva över det?',
        okButton: 'Ja',
        cancelButton: 'Nej'
      }).afterClosed()
        .pipe(filter(Boolean))
        .subscribe(() => this.saveActiveRecipe())
    } else {
      this.saveActiveRecipe()
    }
  }

  private saveActiveRecipe() {
    this.recipesService.saveGeneratedRecipes([this.activeGeneratedRecipe()!])
      .subscribe(() => {
        // Once saved, remove generated menu day from array
        this.generatedRecipes
          .splice(this.generatedRecipes.indexOf(this.activeGeneratedRecipe()!), 1)

        // Update carousel
        this.carouselUtils.update()

        // If it was last recipe, navigate to home screen
        if (!this.generatedRecipes.length) {
          this.router.navigate([INTERNAL_MODULE]).then()
        }
      })
  }
}
